<template>
  <div>
    <v-btn-toggle
      v-model="toggleServiceSelection"
      multiple
    >
      <v-btn
        small
        :color="mapEnabled ? '#E9F5EA' : '#0da344'"
        :style="mapEnabled ? 'color:#0da344' : 'color:white'"
        :disabled="map.isAnimating.includes(meteogramObj.service_id)"
        @click="$emit('toggle-map-btn')"
      >
        <v-icon
          :color="mapEnabled ? '#0da344' : 'white'"
          :class="mapEnabled ? 'me-3' : ''"
          size="22"
        >
          {{ mapEnabled ? 'mdi-check-bold' : '' }}
        </v-icon>
        <v-spacer />
        {{ $t('ServiceCard.map') }}
      </v-btn>
      <v-btn
        small
        :color="meteogramEnabled ? '#E9F5EA' : '#0da344'"
        :style="meteogramEnabled ? 'color:#0da344' : 'color:white'"
        :disabled="(map.meteogramActive && !meteogramEnabled) || map.disableAnimationPlay"
        @click="$emit('toggle-meteogram-btn', !meteogramEnabled)"
      >
        <v-icon
          :color="meteogramEnabled ? '#0da344' : 'white'"
          :class="meteogramEnabled ? 'me-3' : ''"
          size="22"
        >
          {{ meteogramEnabled ? 'mdi-check-bold' : '' }}
        </v-icon>
        <v-spacer />
        {{ $t('Meteogram.name') }}
      </v-btn>
    </v-btn-toggle>
    <meteogram-popup
      v-if="showMeteograms"
      :show-meteograms="showMeteograms"
      :selected-point="selectedPoint"
      :meteogram-obj="meteogramObj"
      @close-dialog="showMeteograms = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { fromLonLat, toLonLat } from 'ol/proj';
import { pointerMove } from 'ol/events/condition';
import { Vector as VectorLayer } from 'ol/layer';
import Collection from 'ol/Collection.js';
import Feature from 'ol/Feature.js';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point.js';
import Overlay from 'ol/Overlay';
import Select from 'ol/interaction/Select';

export default {
  components: {
    MeteogramPopup: () => import('@/components/layout/MeteogramPopup')
  },
  props: {
    meteogramEnabled: {
      type: Boolean,
      default: false
    },
    mapEnabled: {
      type: Boolean,
      default: false
    },
    meteogramObj: {
      type: Object,
      default: () => {}
    },
    locationName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      overlay: null,
      hoverInteraction: null,
      clickInteraction: null,
      showMeteograms: false,
      toggleServiceSelection: [],
      selectedPoint: {}
    };
  },
  computed: {
    ...mapState(['map', 'app_user']),
    serviceCardMeteogram() {
      return this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.layer_type === 'METEOGRAM' && layer.values_.service_id === this.meteogramObj.service_id);
    }
  },
  watch: {
    $route() {},
    meteogramEnabled: {
      async handler(enabled) {
        let isDefaultService = false;

        if (this.app_user.defaultService.GLOBAL?.service_type === 'WS') {
          this.app_user.defaultService.GLOBAL.service_details.forEach(item => {
            if (item.service_id === this.meteogramObj.service_id && item.is_meteogram) {
              isDefaultService = true;
              this.$emit('toggle-meteogram-btn', true);
              item.service_id = false;
            }
          });
        }

        if (enabled) {
          this.map.OLmap.on('singleclick', this.enableMeteogram);

          if (this.meteogramObj.meteograms.length > 0) {
            this.openMeteogramLayer();
          }
        } else {
          if (!isDefaultService && this.serviceCardMeteogram) {
            this.map.OLmap.un('singleclick', this.enableMeteogram);
            this.map.OLmap.removeOverlay(this.overlay);
            this.map.OLmap.removeInteraction(this.hoverInteraction);
            this.removeLayer('METEOGRAM LAYER');
          }
        }
      },
      immediate: true
    },
    locationName: {
      async handler(name) {
        this.selectedPoint.location_name = name;
      }
    }
  },
  beforeDestroy() {
    if (this.serviceCardMeteogram) {
      this.$emit('toggle-meteogram-btn', false);
      this.map.OLmap.un('singleclick', this.enableMeteogram);
      this.map.OLmap.removeOverlay(this.overlay);
      this.map.OLmap.removeInteraction(this.hoverInteraction);
      this.removeLayer('METEOGRAM LAYER');
    }
  },
  methods: {
    ...mapActions(['removeLayer', 'storeToastMessage']),
    enableMeteogram(evt) {
      let locationCoord = [];
      const feature = this.map.OLmap.forEachFeatureAtPixel(evt.pixel, (feature) => {
        return feature.values_.meteogram_id ? feature : undefined;
      });

      if (!feature) {
        this.reverseGeocoding(evt.coordinate);
        locationCoord = toLonLat(evt.coordinate).map(e => e.toFixed(3));
      }

      this.selectedPoint = {
        location_name: feature ? feature.values_.name : '',
        latitude: feature ? feature.values_.latitude : locationCoord[1],
        longitude: feature ? feature.values_.longitude : locationCoord[0]
      };

      this.showMeteograms = true;
    },
    openMeteogramLayer() {
      const features = this.meteogramObj.meteograms.map(obj => {
        const { meteogram_id, location_name, latitude, longitude } = obj;
        const feature = new Feature({
          geometry: new Point(fromLonLat([longitude, latitude], 'EPSG:3857'))
        });
        feature.setProperties({
          name: location_name,
          latitude: latitude,
          longitude: longitude,
          meteogram_id: meteogram_id
        });

        return feature;
      });
      const featureCollection = new Collection(features);

      const vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: featureCollection
        }),
        style: [new Style({
          image: new Circle({
            radius: 7,
            fill: new Fill({ color: '#0da344' }),
            stroke: new Stroke({ color: 'black', width: 2 })
          })
        })]
      });

      vectorLayer.set('layer_type', 'METEOGRAM');
      vectorLayer.set('layer_name', 'METEOGRAM LAYER');
      vectorLayer.set('service_id', this.meteogramObj.service_id);
      vectorLayer.set('service_name', this.meteogramObj.service_name);

      vectorLayer.setZIndex(10);

      this.map.OLmap.addLayer(vectorLayer);
      this.overlayConfig();
    },
    overlayConfig() {
      this.overlay = new Overlay({
        positioning: 'center-left',
        offset: [10, 0],
        element: document.createElement('div')
      });
      this.map.OLmap.addOverlay(this.overlay);

      this.hoverInteraction = new Select({
        layers: function (layer) {
          return layer.values_.layer_type === 'METEOGRAM';
        },
        condition: pointerMove,
        style: [new Style({
          image: new Circle({
            radius: 7,
            fill: new Fill({ color: '#C1E3C2' }),
            stroke: new Stroke({ color: '#0da344', width: 2 })
          })
        })]
      });

      this.hoverInteraction.on('select', (event) => {
        const selectedFeatures = event.target.getFeatures();

        if (selectedFeatures.getLength() > 0) {
          const selectedFeature = selectedFeatures.item(0);
          const name = selectedFeature.get('name');
          this.overlay.setPosition(event.selected[0].getGeometry().flatCoordinates);
          this.overlay.getElement().innerHTML = `
            <style>
              .overlay-style {
                color: white;
                font: bold 10px Open Sans;
                padding: 5px 10px;
                background-color: rgba(0, 0, 0, 0.7);
                border: 1px solid green;
                border-radius: 5px;
                z-index: 100;
              }
            </style>
            <div class="overlay-style">${name}</div>`;
          this.overlay.getElement().style.display = 'block';
        } else {
          this.overlay.getElement().style.display = 'none';
        }
      });

      this.map.OLmap.addInteraction(this.hoverInteraction);
    },
    reverseGeocoding(coord) {
      const coordsLonLat = toLonLat(coord);
      const latlng = {
        lat: coordsLonLat[1],
        lng: coordsLonLat[0]
      };

      // eslint-disable-next-line no-undef
      const reverseGeocoding = new google.maps.Geocoder();
      reverseGeocoding
        .geocode({ location: latlng })
        .then((response) => {
          response.results.forEach(place => {
            if (place.types[0] === 'administrative_area_level_3') {
              this.map.locationName = place.address_components[0].short_name;
            }
          });
        })
        .catch((e) => {
          if (e) {
            this.storeToastMessage({
              text: 'Address not found',
              type: 'warning'
            });
          }
        });
    }
  }
};
</script>
